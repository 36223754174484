import React, { useState, useEffect, useRef } from "react";
import { Result, Spin, Drawer } from "antd";
import axiosInstance from "../helper/axios";
import { useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SuccessPage = () => {
  const query = useQuery();
  const paymentRef = query.get("ref");
  // const riderUserId = query.get("id");

  const [loading, setLoading] = useState(true);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const hasUpdatedRef = useRef(false);

  const updateDbStatuses = async (paymentRef) => {
    try {
      setLoading(true);
      setUpdateSuccess(false);
      const { data } = await axiosInstance().patch(
        "/api/rider/success-package-payment",
        {
          referrence_number: paymentRef,
        }
      );

      if (data) {
        setUpdateSuccess(true);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(">>>>> error updating database statuses");
    }
  };

  useEffect(() => {
    if (paymentRef && !hasUpdatedRef.current) {
      hasUpdatedRef.current = true; // Set the ref to true after the first call
      updateDbStatuses(paymentRef);
    }
  }, [paymentRef]);

  useEffect(() => {
    if (updateSuccess) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      const redirectTimer = setTimeout(() => {
        window.location.href =
          "https://training.dorydelivery.com/orientation-rider";
      }, 10000);

      return () => {
        clearInterval(timer);
        clearTimeout(redirectTimer);
      };
    }
  }, [updateSuccess]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 30,
      }}
    >
      {loading && !updateSuccess ? (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 100,
              }}
              spin
            />
          }
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Result
            status="success"
            title="Thank you for signing up"
            subTitle={
              <p style={{ fontSize: 16 }}>
                Check your email for account confirmation.
              </p>
            }
          />
          {updateSuccess && (
            <div style={{ marginTop: 20, fontSize: 16, color: "grey" }}>
              Redirecting to training site in {countdown} seconds...
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SuccessPage;
